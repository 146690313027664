// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-app-settings-js": () => import("./../src/pages/admin/app-settings.js" /* webpackChunkName: "component---src-pages-admin-app-settings-js" */),
  "component---src-pages-admin-editor-js": () => import("./../src/pages/admin/editor.js" /* webpackChunkName: "component---src-pages-admin-editor-js" */),
  "component---src-pages-admin-index-js": () => import("./../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-playground-js": () => import("./../src/pages/admin/playground.js" /* webpackChunkName: "component---src-pages-admin-playground-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-corporate-governance-js": () => import("./../src/pages/corporate-governance.js" /* webpackChunkName: "component---src-pages-corporate-governance-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-servizi-accessori-additional-insurance-js": () => import("./../src/pages/servizi/accessori/additional-insurance.js" /* webpackChunkName: "component---src-pages-servizi-accessori-additional-insurance-js" */),
  "component---src-pages-servizi-accessori-cod-js": () => import("./../src/pages/servizi/accessori/cod.js" /* webpackChunkName: "component---src-pages-servizi-accessori-cod-js" */),
  "component---src-pages-servizi-accessori-depot-pickup-js": () => import("./../src/pages/servizi/accessori/depot-pickup.js" /* webpackChunkName: "component---src-pages-servizi-accessori-depot-pickup-js" */),
  "component---src-pages-servizi-accessori-destination-pay-js": () => import("./../src/pages/servizi/accessori/destination-pay.js" /* webpackChunkName: "component---src-pages-servizi-accessori-destination-pay-js" */),
  "component---src-pages-servizi-accessori-document-return-js": () => import("./../src/pages/servizi/accessori/document-return.js" /* webpackChunkName: "component---src-pages-servizi-accessori-document-return-js" */),
  "component---src-pages-servizi-accessori-e-com-js": () => import("./../src/pages/servizi/accessori/e-com.js" /* webpackChunkName: "component---src-pages-servizi-accessori-e-com-js" */),
  "component---src-pages-servizi-accessori-exchange-js": () => import("./../src/pages/servizi/accessori/exchange.js" /* webpackChunkName: "component---src-pages-servizi-accessori-exchange-js" */),
  "component---src-pages-servizi-accessori-express-12-js": () => import("./../src/pages/servizi/accessori/express-12.js" /* webpackChunkName: "component---src-pages-servizi-accessori-express-12-js" */),
  "component---src-pages-servizi-accessori-food-service-js": () => import("./../src/pages/servizi/accessori/food-service.js" /* webpackChunkName: "component---src-pages-servizi-accessori-food-service-js" */),
  "component---src-pages-servizi-accessori-gdo-js": () => import("./../src/pages/servizi/accessori/gdo.js" /* webpackChunkName: "component---src-pages-servizi-accessori-gdo-js" */),
  "component---src-pages-servizi-accessori-ident-pin-js": () => import("./../src/pages/servizi/accessori/ident-pin.js" /* webpackChunkName: "component---src-pages-servizi-accessori-ident-pin-js" */),
  "component---src-pages-servizi-accessori-info-js": () => import("./../src/pages/servizi/accessori/info.js" /* webpackChunkName: "component---src-pages-servizi-accessori-info-js" */),
  "component---src-pages-servizi-accessori-pick-return-js": () => import("./../src/pages/servizi/accessori/pick-return.js" /* webpackChunkName: "component---src-pages-servizi-accessori-pick-return-js" */),
  "component---src-pages-servizi-accessori-pick-ship-js": () => import("./../src/pages/servizi/accessori/pick-ship.js" /* webpackChunkName: "component---src-pages-servizi-accessori-pick-ship-js" */),
  "component---src-pages-servizi-accessori-pod-js": () => import("./../src/pages/servizi/accessori/pod.js" /* webpackChunkName: "component---src-pages-servizi-accessori-pod-js" */),
  "component---src-pages-servizi-accessori-return-service-js": () => import("./../src/pages/servizi/accessori/return-service.js" /* webpackChunkName: "component---src-pages-servizi-accessori-return-service-js" */),
  "component---src-pages-servizi-accessori-saturday-js": () => import("./../src/pages/servizi/accessori/saturday.js" /* webpackChunkName: "component---src-pages-servizi-accessori-saturday-js" */),
  "component---src-pages-servizi-accessori-sprinters-js": () => import("./../src/pages/servizi/accessori/sprinters.js" /* webpackChunkName: "component---src-pages-servizi-accessori-sprinters-js" */),
  "component---src-pages-servizi-express-js": () => import("./../src/pages/servizi/express.js" /* webpackChunkName: "component---src-pages-servizi-express-js" */),
  "component---src-pages-servizi-js": () => import("./../src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */),
  "component---src-pages-servizi-logistics-js": () => import("./../src/pages/servizi/logistics.js" /* webpackChunkName: "component---src-pages-servizi-logistics-js" */),
  "component---src-pages-servizi-national-express-js": () => import("./../src/pages/servizi/national-express.js" /* webpackChunkName: "component---src-pages-servizi-national-express-js" */),
  "component---src-pages-servizi-parcel-js": () => import("./../src/pages/servizi/parcel.js" /* webpackChunkName: "component---src-pages-servizi-parcel-js" */),
  "component---src-pages-servizi-safe-plus-js": () => import("./../src/pages/servizi/safe-plus.js" /* webpackChunkName: "component---src-pages-servizi-safe-plus-js" */),
  "component---src-pages-servizi-servizi-accessori-js": () => import("./../src/pages/servizi/servizi-accessori.js" /* webpackChunkName: "component---src-pages-servizi-servizi-accessori-js" */)
}

